/* Override for box menu items so that they will be rendered above the MUI dialog
   These elements are attached directly to the body, so there is no way to have a more specific selector.
   Needed for the add and sort menus in the content picker */
body {
  scrollbar-gutter: stable both-edges;
  overflow-x: hidden;
  --cord-color-brand-primary: #1a6af4;
  --cord-avatar-border-radius: 50%;
  --cord-border-radius-medium: 8px;
  --cord-color-notification: #71bc8f;
}

.dropdown-menu-element {
  z-index: 1400 !important;
}

/*
google maps address suggestion box fix to be above our modals
 */
.pac-container {
  z-index: 99999;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  height: 7px;
  position: relative;
  background-color: #e0e0e0;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #bdbdbd;
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
